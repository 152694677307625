import {
  AppBar as MUIAppBar,
  AppBarProps as MUIAppBarProps,
  Avatar as MUIAvatar,
  Toolbar as MUIToolbar,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import { FC, MouseEvent, ReactNode, forwardRef, useCallback, useState } from "react";
import type { UrlObject } from "url";
import { isSupportedLanguage } from "../../../guards";
import { Language } from "../../../types/language";
import { NewLogo, Text } from "../../data-display";
import { Button, LocaleDropdown } from "../../input-selection";
import { Box, Container } from "../../layout";
import { Link } from "../Link";
import { DropdownMenu } from "./DropdownMenu";
import { HorizontalMenu } from "./HorizontalMenu";

export type AppBarLink = {
  label?: string;
  link: UrlObject;
  nextLink?: boolean;
  icon?: ReactNode | FC;
};

// Special type for menu with 2 levels. We omit link to redeclare it as optionnal to avoid affecting classic menus
export type AppBarLinkWithTwoLevels = Omit<AppBarLink, "link"> & {
  link?: UrlObject;
  links?: AppBarLink[];
};

export type AppBarAction = {
  label?: string;
  action(): void;
};

export interface AppBarProps extends MUIAppBarProps {
  homePage?: AppBarLink;
  title?: string;
  isSideBarVisible?: boolean;
  isUserLoggedIn: boolean;
  locale?: Language;
  locales?: Language[];
  menuPagesTitle?: string;
  onChangeLocale?: (locale: Language) => void;
  pages?: AppBarLink[];
  pathname?: string;
  signIn?: AppBarAction;
  signOut?: AppBarAction;
  userMenuTitle?: string;
  userSettings?: AppBarLink[];
}

export const AppBar = forwardRef<HTMLDivElement, AppBarProps>(
  (
    {
      homePage = {
        link: "/",
        label: "Homepage",
      },
      title,
      isSideBarVisible = false,
      menuPagesTitle = "Site pages links",
      pages = [],
      pathname,
      signIn,
      signOut,
      isUserLoggedIn,
      userMenuTitle = "My space",
      userSettings = [],
      locales = [],
      locale,
      onChangeLocale,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = useCallback((event: MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    }, []);
    const handleOpenUserMenu = useCallback((event: MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    }, []);

    const handleCloseNavMenu = useCallback(() => {
      setAnchorElNav(null);
    }, []);

    const handleCloseUserMenu = useCallback(() => {
      setAnchorElUser(null);
    }, []);

    const handleChangeLocale = useCallback(
      (event: SelectChangeEvent<unknown>) => {
        const locale = event.target.value;

        if (onChangeLocale && isSupportedLanguage(locale, locales)) {
          onChangeLocale(locale);
        }
      },
      [locales, onChangeLocale]
    );

    return (
      <MUIAppBar
        ref={ref}
        position="sticky"
        color="inherit"
        sx={{
          width: "100%",
          display: "flex",
          flexShrink: 0,
          right: 0,
          paddingX: { xs: 1, sm: 2, md: 2, lg: 2, xl: 4 },
          border: "none!important", // to reset all borders before only using bottom one without radius
          borderRadius: "0!important",
          borderBottom: `1px solid ${theme.palette.divider}!important`,
          boxShadow: "none !important",
        }}
        {...props}
      >
        <Container maxWidth={false} paddingY={false}>
          <MUIToolbar disableGutters>
            {/* Mobile and small devices */}
            <DropdownMenu
              anchor={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              id="menu-appbar--small-devices"
              items={[...pages, ...userSettings]}
              menuTitle=""
              onOpenMenu={handleOpenNavMenu}
              onClose={handleCloseNavMenu}
              pathname={pathname}
              signOut={isUserLoggedIn ? signOut : undefined}
              sx={{ display: { xs: "flex", md: "none" } }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            />

            {/* Visible for all the devices */}
            <Link
              href={homePage.link}
              sx={{
                display: { xs: "flex", md: !isSideBarVisible ? "flex" : "none" },
                marginRight: "auto",
              }}
            >
              <NewLogo />
            </Link>

            {title && (
              <Box
                sx={{
                  mr: 4,
                  ml: "auto",
                }}
              >
                <Text component="strong">{title}</Text>
              </Box>
            )}

            {/* Tablet and Desktop */}
            <HorizontalMenu
              id="menu-appbar--pages"
              sx={{
                display: { xs: "none", md: "flex" },
                mr: { md: 2 },
              }}
              items={pages}
              pathname={pathname}
            />

            <DropdownMenu
              anchor={anchorElUser}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              id="menu-appbar--user-settings"
              icon={
                <MUIAvatar sx={{ width: 24, height: 24, bgcolor: theme.palette.secondary.main }} />
              }
              items={userSettings}
              menuTitle={userMenuTitle}
              onOpenMenu={handleOpenUserMenu}
              onClose={handleCloseUserMenu}
              signOut={isUserLoggedIn ? signOut : undefined}
              sx={{ display: { xs: "none", md: "flex" } }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            />

            {/* Visible for all the devices */}
            {signIn && !isUserLoggedIn && (
              <Button
                variant="text"
                size="lg"
                sx={{ mx: 5 }}
                onClick={(event: MouseEvent<HTMLElement>) => {
                  event.preventDefault();
                  signIn.action();
                }}
              >
                <Text>{signIn.label}</Text>
              </Button>
            )}

            <LocaleDropdown
              sx={{ my: 2, ml: 4 }}
              locales={locales}
              value={locale}
              onChange={handleChangeLocale}
            />
          </MUIToolbar>
        </Container>
      </MUIAppBar>
    );
  }
);
